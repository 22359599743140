import React from 'react';
import PageHeader from '../../../components/Headers/PageHeader.jsx';
import InvoicingPayrollCO from "./cards/InvoicingPayrollCO.jsx";

const Invoicing = () => {

  return (
    <div>
      <PageHeader
        title={'Respaldo Facturación.'}
        breadcrumb={[{ href: '#', title: 'Descargas' }]}
      />

      <InvoicingPayrollCO/>
    </div>
  );
}

export default Invoicing;
