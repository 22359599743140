import React, { useState } from 'react';

import {
  FormGroup,
  Form,
  Input,
  Button,
  Row,
} from "reactstrap";

import CustomTable from '../../components/CustomTable.jsx';
import PageHeader from '../../../../components/Headers/PageHeader.jsx';

import { getServipagLogs } from '../../../../api';
import { useAuthToken } from '../../../../hooks/AuthHook.jsx';

const Movements = () => {
  const [movements, setMovements] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [dateFromSelector, setDateFromSelector] = useState();
  const [dateToSelector, setDateToSelector] = useState();
  const authToken = useAuthToken();

  const columns = [
    'Tipo de pago',
    'Identificador',
    'ID Servipag',
    'Cantidad',
    'Fecha de pago',
  ];
  const sizes = [2, 3, 3, 2, 2];
  const typeMapper = {
    '001': 'Pago mensualidad',
    '002': 'Primer pago suscripción',
    '003': 'Pago reserva',
    '004': 'Pago contrato',
  };

  const fetchServipag = async () => {
    if (dateFromSelector && dateToSelector) {
      setIsFetching(true);
      const response = await getServipagLogs(
        authToken,
        {
          _from: dateFromSelector,
          _to: dateToSelector,
        },
      );
      if (response && response.servipag_logs) {
        setMovements(parseResponse(response));
      }
      setIsFetching(false);
    }
  };

  const parseResponse = (response) => {
    const { servipag_logs } = response;
    return servipag_logs.map((movement) => (
      {
        id: movement.identifier,
        data: [
          typeMapper[movement.identifier.substring(0, 3)],
          movement.identifier,
          movement.servipag_uid,
          `$${movement.amount}`,
          `${movement.payment_date} ${movement.payment_hour}`,
        ],
      }
    ));
  };

  return (
    <div>
      <PageHeader
        title={'Ver detalle de rendiciones'}
        breadcrumb={[{ href: '#', title: 'Servipag' }]}
      />
      <CustomTable
        columns={columns}
        sizes={sizes}
        data={movements}
        loading={isFetching}
        renderHeader={() => (
          <Form className="row align-items-end">
            <FormGroup className="my-0 mx-3">
              <label><small>Desde</small></label>
              <Row>
                <Input
                  className="col form-control-muted form-control-sm mx-2"
                  type="date"
                  onChange={(e) => setDateFromSelector(e.target.value)}
                />
              </Row>
            </FormGroup>
            <FormGroup className="my-0 mx-3">
              <label><small>Hasta</small></label>
              <Row>
                <Input
                  className="col form-control-muted form-control-sm mx-2"
                  type="date"
                  onChange={(e) => setDateToSelector(e.target.value)}
                />
              </Row>
            </FormGroup>
            <Button
              color="primary"
              size="sm"
              type="button"
              className="my-3 mx-3"
              onClick={fetchServipag}
              disabled={!dateFromSelector || !dateToSelector}
            >
              Obtener detalle de rendición
            </Button>
          </Form>
        )}
      />
    </div>
  );
}

export default Movements;
