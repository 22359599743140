import React, { useRef, useState } from 'react';

import { Row, Col, Form, FormGroup, Input, Button } from 'reactstrap';

import PageHeader from '../../../../components/Headers/PageHeader.jsx';
import CustomInfo from '../../components/CustomInfo.jsx';

import { uploadServipagLogs } from '../../../../api';
import { useAuthToken } from '../../../../hooks/AuthHook.jsx';

const UploadTxt = () => {
  const [, setResults] = useState();
  const [summary, setSummary] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const fileInputRef = useRef();
  const authToken = useAuthToken();

  const onUpload = async () => {
    if (fileInputRef.current.files.length > 0) {
      setIsFetching(true);
      const body = new FormData();
      body.append('file', fileInputRef.current.files[0]);
      const response = await uploadServipagLogs(authToken, body);
      if (response && response.summary) {
        setSummary(response.summary);
        setResults(response.income);
      }
      setIsFetching(false);
    }
  }

  return (
    <div>
      <PageHeader
        title={'Cargar rendición'}
        breadcrumb={[{ href: '#', title: 'Servipag' }]}
      />
      <CustomInfo
        loading={isFetching}
        showData={!!summary}
        renderHeader={() => (
          <Form>
            <FormGroup className="m-auto">
              <label style={{ textAlign: 'center', width: '100%' }}>
                <small>Selecciona la rendición que deseas cargar (.txt)</small>
              </label>
              <Row>
                <Input
                  className="col form-control-sm mx-2"
                  innerRef={fileInputRef}
                  type="file"
                />
              </Row>
              <Row className="mt-4" style={{ justifyContent: 'center' }}>
                <Button
                  color="primary"
                  size="sm"
                  type="button"
                  onClick={onUpload}
                >
                  Cargar rendición
                </Button>
              </Row>
            </FormGroup>
          </Form>
        )}
        renderInfo={() => (
          <Row className="text-center m-auto py-3">
            <Col>
              <label><small>Resultados</small></label>
              {summary && (
                <>
                  <p><small>Registros procesados: {summary.processed}</small></p>
                  <p><small>Registros cargados con éxito: {summary.success}</small></p>
                  <p><small>Registros cargados con errores: {summary.errors}</small></p>
                  {(summary.errors > 0) && (
                    <p>
                      <small>
                        <em>
                          ¡Es probable que los errores sean causados porque los registros ya fueron cargados antes!
                        </em>
                      </small>
                    </p>
                  )}
                </>
              )}
            </Col>
          </Row>
        )}
      />
    </div>
  );
}

export default UploadTxt;
