const ALLOWED_ROLES = {
  'all': ['accountant_admin', 'accountant','management_admin', 'management', 'super_admin'],
  'admins': ['accountant_admin', 'management_admin', 'super_admin'],
  'accountants': ['accountant_admin', 'accountant', 'super_admin'],
  'management': ['management_admin', 'management', 'super_admin'],
  'accountantAdmin': ['accountant_admin', 'super_admin'],
  'managementAdmin': ['management_admin', 'super_admin'],
  'none': []
}

export const verifyRole = (roles, type) => {
  const allowed = roles.some(role => ALLOWED_ROLES[type].includes(role)) ;
  return allowed
}
