import React, { useContext, useState } from 'react';

import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Button,
  Row,
} from "reactstrap";

import CustomTable from '../components/CustomTable.jsx';
import PageHeader from '../../../components/Headers/PageHeader.jsx';
import MovementInfoModal from '../components/modals/MovementInfoModal.jsx';

import { AdminStateContext } from '../../../providers/AdminStateProvider.jsx';
import { getMovementsBySubscription, getMovement } from '../../../api';
import { useAuthToken } from '../../../hooks/AuthHook.jsx';
const MovementsBySubscription = () => {
  const [movements, setMovements] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState();
  const [typeSelector, setTypeSelector] = useState('default');
  const [dateFromSelector, setDateFromSelector] = useState();
  const [dateToSelector, setDateToSelector] = useState();
  const [modalActive, setModalActive] = useState();
  const [movementInfo, setMovementInfo] = useState();
  const [movementFetching, setMovementFetching] = useState();
  const [adminConfig,] = useContext(AdminStateContext);
  const authToken = useAuthToken();
  const { country } = adminConfig;

  const columns = [
    'Fecha del pago',
    'Concepto',
    'Descripción',
    'Estado',
    'Monto',
  ];
  const conceptMapper = {
    'CONTRACT': 'CONTRATO',
    'LEASE': 'MENSUALIDAD',
    'RESERVATION': 'RESERVA',
    'REFUND': 'REEMBOLSO',
    'CONTRACT_LAW': 'DERECHO DE CONTRATO',
    'RESEARCH': 'ESTUDIO',
  };
  const statusMapper = {
    'PAID': 'Pagado',
    'UNPAID': 'Pendiente de pago',
    'PENDING': 'Pendiente de confirmación',
    'REFUNDED': 'Reembolsado',
    'FROZEN': 'Congelado',
    'LATE': 'Atrasado',
  };

  const sizes = [2, 2, 4, 2, 2];

  const fetchMovementsBySubscription = async () => {
    if (subscriptionId && typeSelector && dateFromSelector && dateToSelector) {
      setIsFetching(true);
      const response = await getMovementsBySubscription(
        country.toLowerCase(),
        authToken,
        subscriptionId,
        typeSelector,
        {
          _from: dateFromSelector,
          _to: dateToSelector,
        },
      );
      if (response && response.movements) {
        setMovements(parseResponse(response));
      }
      setIsFetching(false);
    }
  };

  const parseResponse = (response) => {
    const { movements } = response;
    return movements.map(movement => (
      {
        id: movement.movement_id,
        data: [
          movement.agreed_date.split('T')[0],
          conceptMapper[movement.concept],
          movement.description,
          statusMapper[movement.status],
          `$${movement.total_amount}`,
        ],
      }
    )).reverse();
  };

  const selectMovement = async (id) => {
    if (id) {
      setMovementFetching(true);
      setModalActive(true);
      const response = await getMovement(country.toLowerCase(), authToken, id);
      if (response && response.movement_id) {
        setMovementInfo(response);
      }
      setMovementFetching(false);
    }
  };

  return (
    <div>
      <PageHeader
        title={'Ver movimientos por suscripción'}
        breadcrumb={[{ href: '#', title: 'Movimientos' }]}
      />
      {country === 'CL' ? (
        <Card className="mx-5">
          <CardBody>
            <Row className="text-center m-auto py-3">
              Funcionalidad no implementada
            </Row>
          </CardBody>
        </Card>
      ) : (
        <>
          <MovementInfoModal
            active={modalActive}
            onClose={() => setModalActive(false)}
            movement={movementInfo}
            loading={movementFetching}
          />
          <CustomTable
            columns={columns}
            sizes={sizes}
            data={movements}
            loading={isFetching}
            onClick={selectMovement}
            renderHeader={() => (
              <Form className="row align-items-end">
                <FormGroup className="my-0 mx-3">
                  <label><small>Filtrar por suscripción</small></label>
                  <Row>
                    <Input
                      className="col form-control-muted form-control-sm mx-2"
                      placeholder="ID Suscripción"
                      type="text"
                      onChange={(e) => setSubscriptionId(e.target.value)}
                    />
                  </Row>
                </FormGroup>
                <FormGroup className="my-0 mx-3">
                  <label><small>Ingresa tipo de movimiento</small></label>
                  <Row>
                    <Input
                      className="col form-control-muted form-control-sm mx-2"
                      type="select"
                      onChange={(e) => setTypeSelector(e.target.value)}
                      value={typeSelector}
                    >
                      <option value="default" disabled hidden>-- Tipo de movimiento --</option>
                      <option value="income">Ingresos</option>
                      <option value="outflow">Salidas</option>
                      <option value="profit">Profit</option>
                    </Input>
                  </Row>
                </FormGroup>
                <FormGroup className="my-0 mx-3">
                  <label><small>Desde</small></label>
                  <Row>
                    <Input
                      className="col form-control-muted form-control-sm mx-2"
                      type="date"
                      onChange={(e) => setDateFromSelector(e.target.value)}
                    />
                  </Row>
                </FormGroup>
                <FormGroup className="my-0 mx-3">
                  <label><small>Hasta</small></label>
                  <Row>
                    <Input
                      className="col form-control-muted form-control-sm mx-2"
                      type="date"
                      onChange={(e) => setDateToSelector(e.target.value)}
                    />
                  </Row>
                </FormGroup>
                <Button
                  color="primary"
                  size="sm"
                  type="button"
                  className="my-3 mx-3"
                  onClick={fetchMovementsBySubscription}
                  disabled={
                    typeSelector === 'default'
                    || !dateFromSelector
                    || !dateToSelector
                    || !subscriptionId
                  }
                >
                  Obtener movimientos
              </Button>
              </Form>
            )}
          />
        </>
      )}
    </div>
  );
}

export default MovementsBySubscription;
