import React from "react";

import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col
} from "reactstrap";

const PageHeader = ({ title, breadcrumb }) => (
  <>
    <div className="header pb-3">
      <Container fluid>
        <div className="header-body">
          <Row className="align-items-center pt-4">
            <Col lg="12" xs="12">
              <h6 className="h2 d-inline-block mb-0">{ title }</h6>{" "}
              <Breadcrumb
                className="d-none d-md-inline-block ml-md-4"
                listClassName="breadcrumb-links"
              >
                <BreadcrumbItem>
                  <a href="/admin">
                    <i className="fas fa-home" />
                  </a>
                </BreadcrumbItem>
                {breadcrumb && breadcrumb.map((h, index) => (
                  <BreadcrumbItem key={index}>
                    <a href={h.href}>
                      {h.title}
                    </a>
                  </BreadcrumbItem>
                ))}
                <BreadcrumbItem aria-current="page" className="active">
                  { title }
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  </>
);

export default PageHeader;
