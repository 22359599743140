import React from 'react';
import {
  Table,
  Row,
} from "reactstrap";

const TwoColTable = ({ title, data }) => (
  <Row>
    <p>{title}</p>
    <Table className="align-items-left table-flush" size="sm" responsive>
      <tbody>
        {data.map(row => (
          <tr>
            <th scope="row">{row.item}</th>
            <td>{row.value}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </Row>
);

export default TwoColTable;
