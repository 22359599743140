import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';
import { isInteger } from 'lodash';
import { Row, Col} from 'reactstrap';


const BreakDown = ({ commission, margin }) => {
  return (
    <Fragment>
      {Object.entries(commission).map(([key, value]) => {
        if (isInteger(value)){
          return (
            <Row> 
              <Col style={{marginLeft: margin}} sm={{ size: 'auto' }}>
              <small> {key}: {value} </small>
              </Col> 
            </Row>
          )
        }
        else {
          return (
            <div>
              <Row> <Col> <small> {key}: </small> </Col> </Row>
              <BreakDown commission={commission[key]} margin={margin + 30} />
            </div>
          )
        }
      })}
    </Fragment>
  )                               
}

BreakDown.defaultProps = {
    margin: 0,
  };
  

BreakDown.propTypes = {
    commission: PropTypes.object,
    margin: PropTypes.number,
};

export default BreakDown;
