/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useState, useEffect } from "react";
// react library for routing
import { Route, Switch } from "react-router-dom";
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.jsx";
import AdminFooter from "../components/Footers/AdminFooter.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import { AdminStateContext } from "../providers/AdminStateProvider.jsx";
import { verifyRole } from '../helpers/verifyRole';
import routes from "../routes.js";
import { useAuth0 } from "@auth0/auth0-react";
import { env } from "../env";

import houmLogo from "../assets/img/brand/houmlogo.png";

const AUTH0_ROLES = env.REACT_APP_AUTH0_ROLES;
const AUTH0_LEGACY_TOKEN = env.REACT_APP_AUTH0_LEGACY_TOKEN;

const Admin = (props) => {
  const [adminState, setAdminState] = useContext(AdminStateContext);
  const [sidenavOpen, setSidenavOpen] = useState(true);
  const [roles, setRoles] = useState(null);
  const { user } = useAuth0();
  const { country } = adminState;
  
  useEffect(() => {
    setRoles(user[AUTH0_ROLES]);
    setAdminState({ ...adminState, legacyToken: user[AUTH0_LEGACY_TOKEN] });
  }, [adminState, setAdminState, user]);
  
  if (!roles) return <h1> LOADING </h1>;
  
  const filteredRoutes = routes.map(r => {
    const views = r.views? r.views.filter( view => (!view.roles || verifyRole(roles, view.roles))) : r.views;
    return {...r, views}
  })

  const adminRoutes = filteredRoutes.filter(p => 
    (!p.countries || p.countries.includes(country)) && (!p.roles || verifyRole(roles, p.roles))
  );


  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            exact
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const closeSidenav = () => setSidenavOpen(false);

  const getBrandText = (path, adminRoutes) => {
    for (let i = 0; i < adminRoutes.length; i++) {
      if (
        props.location.pathname.indexOf(
          adminRoutes[i].layout + adminRoutes[i].path
        ) !== -1
      ) {
        return adminRoutes[i].name;
      }
    }
    return "Brand";
  };

  const toggleSidenav = e => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };

  const getNavbarTheme = () => {
    return props.location.pathname.indexOf(
      "admin/alternative-dashboard"
    ) === -1
      ? "dark"
      : "light";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={adminRoutes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: "/",
          imgSrc: houmLogo,
          imgAlt: "..."
        }}
      />
      <div
        className="main-content"
        onClick={closeSidenav}
      >
        <AdminNavbar
          {...props}
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          brandText={getBrandText(props.location.pathname, adminRoutes)}
        />
      <Switch>{getRoutes(adminRoutes)}</Switch>
        <AdminFooter />
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
}

export default Admin;
