import React, { useRef, useState, useContext, useEffect } from "react";
import { isEmpty } from "lodash";
import PageHeader from "../../../components/Headers/PageHeader.jsx";
import BreakDown from "./components/CommissionBreakDown.jsx";
import CommissionForm from "./components/CommissionForm.jsx";
import { Button, Container, Card, CardBody, Row, Col } from "reactstrap";

import { download } from "../../../helpers/downloadFile";
import { parseDateCommssions } from "../../../helpers/parse";
import { useAuthToken } from "../../../hooks/AuthHook.jsx";
import { AdminStateContext } from "../../../providers/AdminStateProvider.jsx";
import {
  getAppraisers,
  getAppraiserCommission,
  getAppraiserReport,
  getAllAppraisersReports,
  getAppraiserResume,
  getAppraisersBankReport,
} from "../../../api";

const Appraisers = () => {
  const authToken = useAuthToken();
  const [appraisers, setAppraisers] = useState([]);
  const [appraiser, setAppraiser] = useState({});
  const [commission, setCommission] = useState({});
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const appraiserIn = useRef();
  const [adminState] = useContext(AdminStateContext);

  useEffect(() => {
    (async function () {
      const results = await getAppraisers();
      setAppraisers(results);
    })();
  }, []);

  const filterByCountry = (appraisers) => appraisers.filter(x => x.country === adminState.longCountry);

  const getCommission = async () => {
    const chosen = appraisers.find(app => app.id === appraiserIn.current.value);
    setAppraiser(chosen);
    const result = await getAppraiserCommission(
      adminState.country.toLowerCase(),
      authToken,
      chosen.id,
      parseDateCommssions(fromDate),
      parseDateCommssions(toDate)
    );
    setCommission(result);
  };

  const getReport = async () => {
    const response = await getAppraiserReport(
      adminState.country.toLowerCase(),
      authToken,
      appraiser.id,
      parseDateCommssions(fromDate),
      parseDateCommssions(toDate),
      appraiser.name
    );
    download(response, `Reporte ${appraiser.name}.xlsx`);
  };


  const getAllReports = async () => {
    const app_list = filterByCountry(appraisers).map(x => ({id: x.id, name: x.name}))
    const response = await getAllAppraisersReports(
      adminState.country.toLowerCase(),
      authToken,
      parseDateCommssions(fromDate),
      parseDateCommssions(toDate),
      app_list
    );
    download(response, 'Reportes.zip');
  };

  const getResume = async () => {
    const app_list = filterByCountry(appraisers).map(x => ({id: x.id, name: x.name}))
    const response = await getAppraiserResume(
      adminState.country.toLowerCase(),
      authToken,
      parseDateCommssions(fromDate),
      parseDateCommssions(toDate),
      app_list
    );
    download(response, 'Resumen.xlsx');
  };

  const getBankReport = async () => {
    const app_list = filterByCountry(appraisers).map(x => ({id: x.id, email: x.email, name: x.name}))
    const response = await getAppraisersBankReport(
      adminState.country.toLowerCase(),
      authToken,
      parseDateCommssions(fromDate),
      parseDateCommssions(toDate),
      app_list
    );
    download(response, 'Resumen.xlsx');
  };


  return (
    <div>
      <PageHeader
        title={"Comisiones Houmers y Homecheckers"}
        breadcrumb={[{ href: "#", title: "Comisiones" }]}
      />

      <Container fluid>
        <Card className='border-0 mb-4'>
          <CardBody className='px-lg-7'>
            <Row className="mt-4" style={{ justifyContent: 'center' }}>
              <Button
                color="primary"
                size="sm"
                type="button"
                onClick={getAllReports}
                disabled={!fromDate || !toDate}
              >
                Descargar todos los reportes
              </Button>
            </Row>
            <Row className="mt-4" style={{ justifyContent: 'center' }}>
              <Button
                color="primary"
                size="sm"
                type="button"
                onClick={getResume}
                disabled={!fromDate || !toDate}
              >
                Descargar resumen reportes
              </Button>
            </Row>
            <Row className="mt-4" style={{ justifyContent: 'center' }}>
              <Button
                color="primary"
                size="sm"
                type="button"
                onClick={getBankReport}
                disabled={!fromDate || !toDate}
              >
                Descargar excel banco
              </Button>
            </Row>
            <Row>
              <CommissionForm
                title={"Houmer"}
                name={appraiser.name}
                inputRef={appraiserIn}
                persons={appraisers}
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
                getCommission={getCommission}
                getReport={getReport}
                commission={commission}
              />
            </Row>
            {!isEmpty(commission) && (
              <Row style={{ marginLeft: 40, marginTop: 30 }}>
                <Col sm={{ size: "auto" }}>
                  <BreakDown commission={commission} />
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Appraisers;
