/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// react library for routing
import { Route, Switch } from 'react-router-dom';

// core components
import AuthNavbar from '../components/Navbars/AuthNavbar.jsx';
import AuthFooter from '../components/Footers/AdminFooter.jsx';

import routes from '../routes.js';

class Auth extends React.Component {
  componentDidMount() {
    document.body.classList.add('bg-secondary');
  }
  componentWillUnmount() {
    document.body.classList.remove('bg-secondary');
  }
  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }
  getRoutes = (routes) =>
    routes.map(({ component, layout, path }, key) =>
      layout === '/auth' ? (
        <Route path={layout + path} component={component} key={key} />
      ) : null
    );
  render() {
    return (
      <>
        <div className='main-content' ref='mainContent'>
          <AuthNavbar />
          <Switch>{this.getRoutes(routes)}</Switch>
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default Auth;
