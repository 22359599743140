/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Home from './views/pages/Home.jsx';
import OwnerInfo from './views/pages/owners/OwnerInfo.jsx';
import Accounts from './views/pages/owners/Accounts.jsx';
import NewAccount from './views/pages/owners/NewAccount.jsx';
import Movements from './views/pages/movements/Movements.jsx';
import MovementsBySubscriptions from './views/pages/movements/MovementsBySubscriptions.jsx';
import Downloads from './views/pages/downloads/Downloads.jsx';
import Income from './views/pages/thirdparties/servipag/Income.jsx';
import ServipagMovement from './views/pages/thirdparties/servipag/ServipagMovement.jsx';
import UploadTxt from './views/pages/thirdparties/servipag/UploadTxt.jsx';
import Login from './views/pages/login/Login.jsx';
import Appraisers from './views/pages/commissions/Appraisers.jsx';
import Executives from './views/pages/commissions/Executives.jsx';
import Payroll from './views/pages/tools/payroll/Payroll'
import Invoicing from './views/pages/downloads/Invoicing.jsx';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    layout: '/auth',
  },
  {
    path: '',
    name: 'Inicio',
    component: Home,
    layout: '/admin',
  },
  {
    collapse: true,
    name: 'Propietarios',
    icon: 'fas fa-home text-orange',
    state: 'ownersCollapse',
    roles: 'accountants',
    views: [
      {
        path: '/owners/info',
        name: 'Obtener información del propietario',
        component: OwnerInfo,
        layout: '/admin',
        sidebar: true,
        roles: 'accountants',
      },
      {
        path: '/accounts',
        name: 'Ver cuentas bancarias del propietario',
        component: Accounts,
        layout: '/admin',
        sidebar: true,
        roles: 'accountants',
      },
      {
        path: '/accounts/new',
        name: 'Ingresar nueva cuenta bancaria',
        component: NewAccount,
        layout: '/admin',
        sidebar: true,
        roles: 'admins',
      },
    ],
  },
  {
    collapse: true,
    name: 'Movimientos',
    icon: 'fas fa-chart-line text-green',
    state: 'movementsCollapse',
    roles: 'accountants',
    views: [
      {
        path: '/movements',
        name: 'Ver movimientos',
        component: Movements,
        layout: '/admin',
        sidebar: true,
        roles: 'accountants',
      },
      {
        path: '/movements/subscriptions',
        name: 'Ver movimientos por suscripción',
        component: MovementsBySubscriptions,
        layout: '/admin',
        sidebar: true,
        roles: 'accountants',
      },
    ],
  },
  {
    collapse: true,
    name: 'Descargas',
    icon: 'fas fa-download text-blue',
    state: 'downloadsCollapseCO',
    roles: 'accountants',
    countries: ['CO'],
    views: [
      {
        path: '/downloads',
        name: 'Descargar documentos',
        component: Downloads,
        layout: '/admin',
        sidebar: true,
        roles: 'accountants',
      },
      {
        path: '/downloads/invocing',
        name: 'Facturación',
        component: Invoicing,
        layout: '/admin',
        sidebar: true,
        roles: 'accountants'
      },
    ],
  },
  {
    collapse: true,
    name: 'Descargas',
    icon: 'fas fa-download text-blue',
    state: 'downloadsCollapse',
    roles: 'accountants',
    countries: ['CL', 'MX'],
    views: [
      {
        path: '/downloads',
        name: 'Descargar documentos',
        component: Downloads,
        layout: '/admin',
        sidebar: true,
        roles: 'accountants',
      },
    ],
  },
  {
    collapse: true,
    name: 'Servipag',
    icon: 'fas fa-credit-card text-yellow',
    state: 'servipagCollapse',
    countries: ['CL'],
    roles: 'accountants',
    views: [
      {
        path: '/thirdparties/servipag',
        name: 'Ver pagos realizados',
        component: Income,
        layout: '/admin',
        sidebar: true,
        roles: 'accountants',
      },
      {
        path: '/thirdparties/servipag/movement',
        name: 'Obtener movimiento asociado',
        component: ServipagMovement,
        layout: '/admin',
        sidebar: true,
        roles: 'accountants',
      },
      {
        path: '/thirdparties/servipag/upload',
        name: 'Cargar rendición',
        component: UploadTxt,
        layout: '/admin',
        sidebar: true,
        roles: 'accountantAdmin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Comisiones',
    icon: 'fas fa-calculator text-orange',
    state: 'commissionsColapse',
    countries: ['CL'],
    roles: 'accountants',
    views: [
      {
        path: '/commissions/appraisers',
        name: 'Houmers y Homecheckers',
        component: Appraisers,
        layout: '/admin',
        sidebar: true,
        roles: 'accountants',
      },
      {
        path: '/commissions/executives',
        name: 'Ejecutivos y LQ',
        component: Executives,
        layout: '/admin',
        sidebar: true,
        roles: 'accountants',
      }
    ],
  },
  {
    collapse: true,
    name: 'Herramientas',
    icon: 'fas fa-cog text-gray',
    state: 'toolsColapse',
    countries: ['CL'],
    roles: 'accountants',
    views: [
      {
        path: '/tools/payroll',
        name: 'Nóminas de pago',
        component: Payroll,
        layout: '/admin',
        sidebar: true,
        roles: 'accountants',
      },
    ],
  },
];

export default routes;
