import React from 'react';
import { Container, Card, CardBody, Row, Col } from 'reactstrap';
import PageHeader from '../../../components/Headers/PageHeader.jsx';

const NewAccount = () => {
  return (
    <div>
      <PageHeader
        title={'Ingresar nueva cuenta bancaria'}
        breadcrumb={[{ href: '#', title: 'Propietarios' }]}
      />
      <Container>
        <Card>
          <CardBody>
            <Row className="text-center m-auto py-3">
              <Col>
                <p><b>¡Funcionalidad no implementada aún!</b></p>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}

export default NewAccount;
