import React from 'react';
import { PropTypes } from "prop-types";

import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
} from "reactstrap";


const CustomTable = ({
  columns,
  data,
  onClick,
  page,
  totalPages,
  changePage,
  hasIndex,
  sizes,
  renderHeader,
  loading,
}) => {
  const hclasses = sizes.map(size => size ? `text-truncate col-${size}` : 'text-truncate col');
  return (
    <Container fluid>
      <Card>
        <CardHeader className="border-0 m-auto">{renderHeader()}</CardHeader>
        <Table className="align-items-center table-flush" hover responsive>
          <thead className="thead-light">
            <tr className="row mx-0">
              {columns.map((h, hIndex) => (
                <th key={hIndex} className={hclasses[hIndex]}>{h}</th>
              ))}
            </tr>
          </thead>
          {loading && (
            <div className="lds-facebook m-auto d-flex">
              <div></div><div></div><div></div>
            </div>
          )}
          {(data.length !== 0 && !loading) && (
            <tbody>
              {data.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  onClick={() => onClick ? onClick(row.id) : {}}
                  className="row mx-0"
                  style={onClick ? ({ cursor: 'pointer' }) : ({})}
                >
                  {row.data && row.data.map((item, colIndex) => (colIndex === 0 && hasIndex) ? (
                    <th key={colIndex} scope="row" className={hclasses[colIndex]}>{item}</th>
                  ) : (
                    <td key={colIndex} className={hclasses[colIndex]}>{item}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
          {(data.length === 0 && !loading) && (
            <div className="text-center my-3">
              <em><small>No hay datos</small></em>
            </div>
          )}
        </Table>
        <CardFooter className="py-4">
          {totalPages > 0 && (
            <nav aria-label="...">
              <Pagination
                className="pagination justify-content-end mb-0 pagination-sm"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem disabled={page === 1}>
                  <PaginationLink onClick={() => changePage(1)}>
                    <i className="fas fa-angle-double-left" />
                    <span className="sr-only">First</span>
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={page === 1}>
                  <PaginationLink onClick={() => changePage(page - 1)}>
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                {page > 1 && (
                  <PaginationItem>
                    <PaginationLink onClick={() => changePage(page - 1)}>
                      {page - 1}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem active={true}>
                  <PaginationLink>
                    {page} <span className="sr-only">(current)</span>
                  </PaginationLink>
                </PaginationItem>
                {page < totalPages && (
                  <PaginationItem>
                    <PaginationLink onClick={() => changePage(page + 1)}>
                      {page + 1}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem disabled={page === totalPages}>
                  <PaginationLink onClick={() => changePage(page + 1)}>
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={page === totalPages}>
                  <PaginationLink onClick={() => changePage(totalPages)}>
                    <i className="fas fa-angle-double-right" />
                    <span className="sr-only">Last</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          )}
        </CardFooter>
      </Card>
    </Container>
  );
}

CustomTable.defaultProps = {
  columns: [],
  data: [],
  onClick: undefined,
  page: 1,
  changePage: (newPage) => { },
  hasIndex: false,
  sizes: [],
  renderHeader: () => { },
  totalPages: 1,
  loading: false,
};

CustomTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
  page: PropTypes.number,
  changePage: PropTypes.func,
  hasIndex: PropTypes.bool,
  sizes: PropTypes.arrayOf(PropTypes.number),
  renderHeader: PropTypes.func,
  totalPages: PropTypes.number,
  loading: PropTypes.bool,
};

export default CustomTable;
