import React, { useContext, useState } from 'react';

import { Button, Input, Row, Form, FormGroup } from 'reactstrap';

import PageHeader from '../../../../components/Headers/PageHeader.jsx';
import CustomInfo from '../../components/CustomInfo.jsx';

import { AdminStateContext } from '../../../../providers/AdminStateProvider.jsx';
import TwoColTable from '../../components/TwoColTable.jsx';
import { getMovementByServipagUID } from '../../../../api';
import { parseMoney } from '../../../../helpers/parse';

const ServipagMovement = () => {
  const [adminState] = useContext(AdminStateContext);
  const [isFetching, setIsFetching] = useState();
  const [movements, setMovements] = useState([]);
  const [servipagUid, setServipagUid] = useState();
  const { legacyToken } = adminState;

  const onClick = async () => {
    if (servipagUid) {
      setIsFetching(true);
      const response = await getMovementByServipagUID(legacyToken, servipagUid);
      if (response && response.result) {
        setMovements(response.result);
      }
      setIsFetching(false);
    }
  }

  return (
    <div>
      <PageHeader
        title={'Ver movimiento asociado'}
        breadcrumb={[{ href: '#', title: 'Servipag' }]}
      />
      <CustomInfo
        loading={isFetching}
        showData={movements.length > 0}
        renderHeader={() => (
          <Form>
            <FormGroup className="m-auto">
              <label><small>Ingresar servipag UID</small></label>
              <Row>
                <Input
                  className="col form-control-sm mx-2"
                  placeholder="Servipag UID"
                  type="text"
                  onChange={e => setServipagUid(e.target.value)}
                />
                <Button
                  color="primary"
                  size="sm"
                  type="button"
                  disabled={!servipagUid}
                  onClick={onClick}
                >
                  Buscar movimiento
                </Button>
              </Row>
            </FormGroup>
          </Form>
        )}
        renderInfo={() => (
          <>
            <h4>Coincidencias</h4>
            {movements.map((movement) => (
              <TwoColTable
                title={movement.type}
                data={[
                  {item: 'ID Solicitud', value: movement.quotation?.id || '-'},
                  {item: 'ID Propiedad', value: movement.property?.id || '-'},
                  {
                    item: 'Plan garantizado',
                    value: [13, 19].includes(movement.plan?.id) ? 'Sí' : 'No',
                  },
                  {item: 'Estado', value: movement.payment?.state},
                  {item: 'Servipag UID', value: movement.payment?.servipag_uid},
                  {item: 'Monto', value: parseMoney(movement.payment?.amount)},
                  {item: 'Fecha recaudación', value: movement.payment?.accounting?.payment_date},
                  {
                    item: 'Fecha pago propietario',
                    value: movement.payment?.accounting?.deposit_date,
                  },
                ]}
              />
            ))}
          </>
        )}
      />
    </div>
  );
}

export default ServipagMovement;
