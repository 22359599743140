import React, { useState, useEffect } from 'react';

import { getObject, setObject } from '../helpers/storage';

const AdminStateContext = React.createContext([{}, () => {}]);

const AdminStateProvider = (props) => {
  const [adminData, setAdminData] = useState({
      country: 'CL',
      longCountry: 'Chile',
      legacyToken: null,
  });

  useEffect(() => {
    const data = getObject('adminData');
    if (data && Object.keys(data).length > 0) {
      setAdminData(data);
    }
  }, []);

  useEffect(() => {
    setObject('adminData', adminData);
  }, [adminData]);

  return (
    <AdminStateContext.Provider value={[adminData, setAdminData]}>
      {props.children}
    </AdminStateContext.Provider>
  );
}

export { AdminStateContext, AdminStateProvider };
