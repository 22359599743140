import React, { useContext } from 'react';
import { isEmpty } from "lodash";
import {Row, FormGroup, Form, Input, Button} from 'reactstrap';

import { AdminStateContext } from '../../../../providers/AdminStateProvider.jsx';

const CommissionForm = ({title, name, inputRef, persons, fromDate, setFromDate, toDate, setToDate, getCommission, getReport, commission}) => {

  const [adminState,] = useContext(AdminStateContext);

  return (
    <Form className="row align-items-end justify-content-center">
      <FormGroup className="my-0 mx-6 ">
        <label><small>{title}: {name}</small></label>
        <Row>
          <Input
            className="col form-control-muted form-control-sm mx-2"
            placeholder="Nombre"
            type="select"
            innerRef={inputRef}
          >
            {
              persons.filter(person => person.country === adminState.longCountry).map(person => 
                <option key= {person.id} value={person.id}> {person.name} </option>)
            }
          </Input>
        </Row>
      </FormGroup>
      
      <FormGroup className="my-0 mx-6">
        <label><small>Desde</small></label>
        <Row>
          <Input
            className="col form-control-muted form-control-sm mx-2"
            type="date"
            onChange={(e) => setFromDate(e.target.value)}
          />
        </Row>
      </FormGroup>
      <FormGroup className="my-0 mx-6">
        <label><small>Hasta</small></label>
        <Row>
          <Input
            className="col form-control-muted form-control-sm mx-2"
            type="date"
            onChange={(e) => setToDate(e.target.value)}
          />
        </Row>
      </FormGroup>
      <Button
        color="primary"
        size="sm"
        type="button"
        onClick={getCommission}
        disabled={
          !fromDate || !toDate
        }
      >
          Obtener comisión
      </Button>
      {
        !isEmpty(commission)  &&
        <Button
          color="primary"
          size="sm"
          type="button"
          onClick={getReport}
        >
            Descargar informe
        </Button>
      }
    </Form>
  )
}


export default CommissionForm;
