import React, { useState } from "react";

import { Button } from "reactstrap";


const DownloadButton = ({ disabled, onClick, text }) => {
  const [isFetching, setIsFetching] = useState(false);
  if (isFetching) {
    return <div className="lds-dual-ring"></div>;
  }
  return (
    <Button
      className="font-weight-bold"
      onClick={async e => {
        setIsFetching(true);
        await onClick(e);
        setIsFetching(false);
      }}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export default DownloadButton;
