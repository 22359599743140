import React from 'react';

import {
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Input,
} from 'reactstrap';

import CustomInfo from '../components/CustomInfo.jsx';
import PageHeader from '../../../components/Headers/PageHeader.jsx';

const OwnerInfo = () => {

  return (
       <div>
        <PageHeader
          title={'Información del propietario'}
          breadcrumb={[{ href: '#', title: 'Propietarios' }]}
        />
        <CustomInfo
          loading={false}
          showData={true}
          renderHeader={() => (
            <Form>
              <FormGroup className="m-auto">
                <label><small>Ingresa el ID de la suscripción</small></label>
                <Row>
                  <Input
                    className="col form-control-muted form-control-sm mx-2"
                    placeholder="ID de suscripción"
                    type="text"
                    disabled
                  />
                  <Button
                    color="primary"
                    size="sm"
                    type="button"
                  >
                    Obtener información
                  </Button>
                </Row>
              </FormGroup>
            </Form>
          )}
          renderInfo={() => (
            <Row className="text-center m-auto py-3">
              <Col>
                <p><small>¡Funcionalidad no implementada aún!</small></p>
              </Col>
            </Row>
          )}
        />
      </div>
  );
}

export default OwnerInfo;
