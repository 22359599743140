import React, { useState } from "react";
import {
    Row, Col
} from "reactstrap";
import { FormGroup, Input, Form, Button } from "reactstrap";
import CustomInfo from '../../components/CustomInfo.jsx';
import moment from "moment"
import { getInvoicingReportDetail } from '../../../../api';
import { useAuthToken } from '../../../../hooks/AuthHook.jsx';

const InvoicingPayrollCO = () => {

    const authToken = useAuthToken();

    const [loading, setLoading] = useState(false)
    const [dateFrom, setDateFrom] = useState(moment())
    const [dateTo, setDateTo] = useState(moment())
    const [errorDownload, setErrorDownload] = useState(false)


    const downloadPayroll = async () => {

        // Get the information
        setLoading(true)
        setErrorDownload(false)
        const response = await getInvoicingReportDetail(
            authToken,
            dateFrom,
            dateTo)
        setLoading(false)

        // Download file in case of response, otherwise, show error message
        if (response) {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(new Blob([response]));
            const name = `invoice_payroll_${dateFrom}_${dateTo}.zip`;
            link.setAttribute("download", name);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
        else {
            setErrorDownload(true)
        }
    }

    return <CustomInfo
        showData={true}
        loading={loading}
        renderHeader={
            e => (
                <Row className="align-items-center m-auto">
                    <span className="mr-4">Descarga de respaldo de detalle de facturación.</span>
                </Row>
            )
        }
        renderInfo={
            e => (
                <Form onSubmit={e => e.preventDefault()}>
                    <FormGroup className="m-auto">
                        {/** Form to download the invoice data */}
                        <Row>
                            <Col>
                                <FormGroup className="my-0 mx-3">
                                    <label><small>Fecha incial</small></label>
                                    <Input
                                        className="col form-control-muted form-control-sm mx-2"
                                        type="date"
                                        onChange={(e) => setDateFrom(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>

                            <Col>
                                <FormGroup className="my-0 mx-3">
                                    <label><small>Fecha final</small></label>
                                    <Input
                                        className="col form-control-muted form-control-sm mx-2"
                                        type="date"
                                        onChange={(e) => setDateTo(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>

                            <Col>
                                <Button
                                    color="primary"
                                    className="my-3 mx-3"
                                    size="sm"
                                    type="button"
                                    onClick={downloadPayroll}
                                >
                                    Descargar Reporte
                                </Button>
                            </Col>
                        </Row>

                        {
                            /** Show error message only if the download fails */
                            errorDownload && (
                                <Row style={{ justifyContent: 'center', color: 'red' }}>
                                    Error en los parámetros ingresados o en los servidores.
                                </Row>
                            )
                        }

                    </FormGroup>
                </Form >
            )
        }
    />
}

export default InvoicingPayrollCO;
