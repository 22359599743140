/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Redirect } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "../../../components/Headers/AuthHeader.jsx";

const Login = () => {
  
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return (
      <Redirect to="/admin" />
    )
  }
  return (
    <>
      <AuthHeader />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-lighter border-0 mb-0">
              <CardHeader className="bg-transparent">
                <div className="text-muted text-center mt-4">
                  <h4>Ingresar como administrador</h4>
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">                 
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="info"
                      type="button"
                      onClick={loginWithRedirect}
                    >
                      Ingresar
                    </Button>
                  </div>               
              </CardBody>
            </Card>
          
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
