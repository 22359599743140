import React, { useEffect, useRef, useContext } from "react";
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import ReactFlagsSelect from 'react-flags-select';
import {
  Card,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  Nav,
  Container,
} from "reactstrap";

import { AdminStateContext } from '../../providers/AdminStateProvider.jsx';
import { getObject, setObject } from '../../helpers/storage';
import { useAuth0 } from '@auth0/auth0-react';

import profileImg from '../../assets/img/theme/profile.png';


const COUNTRIES = {
  'CL': 'Chile',
  'CO': 'Colombia',
  "MX": "Mexico",
}

const AdminNavbar = (props) => {

  const user = getObject("userData");
  const [adminState, setAdminState] = useContext(AdminStateContext);
  const countryRef = useRef(null);
  const { logout } = useAuth0();

  const handleLogout = () => {
    setObject('adminData', {});
    logout({returnTo: `${window.location.origin}/auth`})
    props.history.push('/auth');
  };

  const updateCountry = country => 
    setAdminState({ ...adminState, country, longCountry: COUNTRIES[country] });

  useEffect(() => {
    if (countryRef.current) {
      const { country } = adminState;
      switch (country) {
        case 'CL':
          countryRef.current.setState({ selected: 'CL' });
          break;
        case 'CO':
          countryRef.current.setState({ selected: 'CO' });
          break;
        case "MX":
          countryRef.current.setState({ selected: 'MX' });
          break;
        default:
          console.error('Country not supported');
          countryRef.current.setState({ selected: 'CL' });
      }
    }
  }, [adminState]);

  return (
    <>
      <Navbar className="navbar-top navbar-expand border-bottom navbar-dark bg-warning">
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <Nav className="align-items-center ml-auto" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={profileImg}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {user.name}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Hola {user.name}!</h6>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={handleLogout}>
                    <i className="ni ni-user-run" />
                    <span>Salir</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <Card className="mb-0 ml-5" style={{ backgroundColor: 'lightsalmon' }}>
                <ReactFlagsSelect
                  selected={adminState.country}
                  countries={["CL", "CO", "MX"]}
                  customLabels={{"CL": "CL", "CO": "CO", "MX": "MX"}}
                  showSelectedLabel={true}
                  selectedSize={18}
                  onSelect={updateCountry}
                  ref={countryRef}
                  className="pb-0 pt-0 country-selector"
                />
              </Card>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark"
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"])
};

export default withRouter(AdminNavbar);
