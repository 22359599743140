/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Auth0Provider } from '@auth0/auth0-react';

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-datetime/css/react-datetime.css";
// plugins styles downloaded
import "./assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "./assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "./assets/vendor/select2/dist/css/select2.min.css";
import "./assets/vendor/quill/dist/quill.core.css";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
// core ./styles
import "./assets/css/argon-dashboard-pro-react.css";

import AdminLayout from "./layouts/Admin.jsx";
import AuthLayout from "./layouts/Auth.jsx";
import PrivateComponent from "./PrivateComponent.jsx";
import { AdminStateProvider } from "./providers/AdminStateProvider.jsx";
import { env } from "./env.js";

const CLIENT_ID = env.REACT_APP_CLIENT_ID;
const DOMAIN = env.REACT_APP_DOMAIN;
const AUDIENCE = env.REACT_APP_AUDIENCE;

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <PrivateComponent>
        <Component {...props} />
      </PrivateComponent>
    )}
  />
)

ReactDOM.render(
  <Auth0Provider
    domain={DOMAIN}
    clientId={CLIENT_ID}
    redirectUri={`${window.location.origin}/admin`}
    audience={AUDIENCE}
  >
    <BrowserRouter>
      <AdminStateProvider>
        <Switch>
          <Route path="/auth" component={props => <AuthLayout {...props} />} />
          <PrivateRoute path="/admin" component={AdminLayout} />
          <Redirect from="*" to="/auth" />
        </Switch>
      </AdminStateProvider>
    </BrowserRouter>
  </Auth0Provider>,
  document.getElementById("root")
);
