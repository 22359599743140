import React, { useRef, useState } from "react";

import { Button, CustomInput, Form, FormGroup } from "reactstrap";


const UploadButton = ({ disabled, onClick, text }) => {
  const [isFetching, setIsFetching] = useState(false);
  const fileInputRef = useRef();

  if (isFetching) {
    return <div className="lds-dual-ring"></div>;
  }
  return (
    <Form>
      <FormGroup>
        <CustomInput
          type="file"
          name="uploadFile"
          className="d-flex"
          innerRef={fileInputRef}
        />
        <Button
          className="font-weight-bold"
          onClick={async () => {
            setIsFetching(true);
            await onClick(fileInputRef);
            setIsFetching(false);
          }}
          disabled={disabled}
        >
          {text}
        </Button>
      </FormGroup>
    </Form>
  );
};

export default UploadButton;
