import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PropTypes } from "prop-types";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Button,
  Row,
  Table,
  Col,
  Collapse,
} from "reactstrap";

import SideModal from './SideModal.jsx';
import { AdminStateContext } from '../../../../providers/AdminStateProvider.jsx';
import { getGroup } from '../../../../api';
import { useAuthToken } from '../../../../hooks/AuthHook.jsx';

const MovementInfoModal = ({ active, onClose, movement, loading }) => {
  let history = useHistory();
  const [groupsCollapseOpened, setGroupsCollapseOpened] = useState(false);
  const [filesCollapseOpened, setFilesCollapseOpened] = useState(false);
  const [documentsCollapseOpened, setDocumentsCollapseOpened] = useState(false);
  const [methodInfoCollapseOpened, setMethodInfoCollapseOpened] = useState(false);
  const [group, setGroup] = useState([]);
  const [fetchingGroup, setFetchingGroup] = useState(false);
  const [adminState,] = useContext(AdminStateContext);
  const authToken = useAuthToken();

  const typeMapper = {
    'INCOME': 'Recaudación',
    'OUTFLOW': 'Salida',
    'PROFIT': 'Ingreso Houm',
  };
  const conceptMapper = {
    'CONTRACT': 'Contrato',
    'LEASE': 'Mensualidad',
    'RESERVATION': 'Reserva',
    'REFUND': 'Reembolso',
    'CONTRACT_LAW': 'Derecho de contrato',
    'RESEARCH': 'Pago de estudio',
  };
  const statusMapper = {
    'PAID': {
      value: 'Pagado',
      color: 'yellowgreen',
    },
    'UNPAID': {
      value: 'Pendiente de pago',
      color: 'gray',
    },
    'PENDING': {
      value: 'Pendiente de confirmación',
      color: 'orange',
    },
    'REFUNDED': {
      value: 'Reembolsado',
      color: 'gold',
    },
    'FROZEN': {
      value: 'Congelado',
      color: 'lightblue',
    },
    'LATE': {
      value: 'Atrasado',
      color: 'purple',
    },
    'CANCELED': {
      value: 'Anulado',
      color: 'red',
    },
  };
  const paymentMethodMapper = {
    'UNKNOWN': 'Desconocido',
    'SERVIPAG': 'Servipag',
    'PLACETOPAY': 'PlaceToPay',
    'TRANSFER': 'Transferencia directa a cuenta Houm',
  };
  const planMapper = {
    'GUARANTEED': 'Garantizado',
    'NORMAL': 'Normal',
  };
  const accountTypeMapper = {
    'CURRENT': 'Corriente',
    'SAVING': 'Ahorro',
    'SIGHT': 'Vista',
  };

  useEffect(() => {
    setGroup([]);
    setFetchingGroup(false);
    setGroupsCollapseOpened(false);
    setFilesCollapseOpened(false);
    setDocumentsCollapseOpened(false);
  }, [movement]);


  const toggleGroupModal = async () => {
    setGroupsCollapseOpened(!groupsCollapseOpened);
    if (group.length === 0) {
      setFetchingGroup(true);
      const response = await getGroup(
        adminState.country.toLowerCase(),
        authToken,
        movement.group_id,
      );
      if (response && response.movements) {
        setGroup(response.movements);
      }
      setFetchingGroup(false);
    }
  };

  const showMovement = movementId => {
    history.push({ search: `?movement_id=${movementId}` });
  };

  return (
    <SideModal
      active={active}
      onClose={onClose}
      renderHeader={() => (
        <h5 className="modal-title">
          Detalle del movimiento
        </h5>
      )}
      renderFooter={() => (
        <Button
          color="warning"
          data-dismiss="modal"
          type="button"
          onClick={onClose}
        >
          Cerrar
        </Button>
      )}
    >
      {loading && (
        <Container>
          <div className="lds-facebook m-auto d-flex">
            <div></div><div></div><div></div>
          </div>
        </Container>
      )}
      {!loading && (
        <Container>
          <Row>
            <Col>
              <p>Información del pago</p>
              <Table className="align-items-left table-flush" size="sm" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Item</th>
                    <th>Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">ID</th>
                    <td>{movement.movement_id}</td>
                  </tr>
                  <tr>
                    <th scope="row">Tipo de movimiento</th>
                    <td>{typeMapper[movement.type]}</td>
                  </tr>
                  <tr>
                    <th scope="row">Concepto</th>
                    <td>{conceptMapper[movement.concept]}</td>
                  </tr>
                  <tr>
                    <th scope="row">Descripción del pago</th>
                    <td>{movement.description ? movement.description : '-'}</td>
                  </tr>
                  {movement.plan_type && (
                    <tr>
                      <th scope="row">Tipo de plan</th>
                      <td>{`${planMapper[movement.plan_type]} ${movement.plan_percentage ? movement.plan_percentage : ''}`}</td>
                    </tr>
                  )}
                  <tr>
                    <th scope="row">Estado</th>
                    {movement.status && (
                      <td>
                        <code
                          style={{
                            color: statusMapper[movement.status].color,
                            fontSize: '100%',
                            border: 'solid 1px',
                            padding: '3px',
                            borderRadius: '5px',
                          }}
                        >
                          {statusMapper[movement.status].value}
                        </code>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <th scope="row">Fecha estipulada de pago</th>
                    <td>{movement.agreed_date}</td>
                  </tr>
                  {movement.date && (
                    <tr>
                      <th scope="row">Fecha pago</th>
                      <td>{movement.date}</td>
                    </tr>
                  )}
                  {movement.date && (
                    <tr>
                      <th scope="row">Hash</th>
                      <td>-</td>
                    </tr>
                  )}
                  <tr>
                    <th scope="row">Moneda</th>
                    <td>{movement.currency}</td>
                  </tr>
                  <tr>
                    <th scope="row">Tipo de pago</th>
                    <td>{paymentMethodMapper[movement.method]}</td>
                  </tr>
                  <tr>
                    <th scope="row">Salud</th>
                    <td>{movement.healthy ? 'OK' : 'Con Errores'}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col>
              <p>Identificadores adicionales</p>
              <Table className="align-items-left table-flush" size="sm" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Entidad</th>
                    <th>ID</th>
                  </tr>
                </thead>
                <tbody>
                  {movement.subscription_id && (
                    <tr>
                      <th scope="row">Suscripción</th>
                      <td>{movement.subscription_id}</td>
                    </tr>
                  )}
                  {movement.property_id && (
                    <tr>
                      <th scope="row">Propiedad</th>
                      <td>{movement.property_id}</td>
                    </tr>
                  )}
                  {movement.servipag_uid && (
                    <tr>
                      <th scope="row">Servipag</th>
                      <td>{movement.servipag_uid}</td>
                    </tr>
                  )}
                  {movement.placetopay_request && (
                    <tr>
                      <th scope="row">PlaceToPay</th>
                      <td>{movement.placetopay_request}</td>
                    </tr>
                  )}
                  {movement.payroll_id && (
                    <tr>
                      <th scope="row">Planilla</th>
                      <td>{movement.payroll_id}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <br />
              {movement.person_data && (
                <>
                  <p>
                    Datos de la entidad relacionada {movement.type === 'INCOME' ? '(Origen)' : '(Receptor)'}
                  </p>
                  <Table className="align-items-left table-flush" size="sm" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th>Item</th>
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">ID Persona</th>
                        <td>{movement.person_data.person_id}</td>
                      </tr>
                      <tr>
                        <th scope="row">Nombre</th>
                        <td>{movement.person_data.full_name}</td>
                      </tr>
                      <tr>
                        <th scope="row">Email</th>
                        <td>{movement.person_data.email}</td>
                      </tr>
                      {movement.person_data.document_type && (
                        <tr>
                          <th scope="row">Tipo de documento</th>
                          <td>{movement.person_data.document_type}</td>
                        </tr>
                      )}
                      <tr>
                        <th scope="row">Documento</th>
                        <td>{movement.person_data.document}</td>
                      </tr>
                      {movement.person_data.bank_name && (
                        <tr>
                          <th scope="row">Banco</th>
                          <td>{movement.person_data.bank_name}</td>
                        </tr>
                      )}
                      {movement.person_data.bank_code && (
                        <tr>
                          <th scope="row">Código banco</th>
                          <td>{movement.person_data.bank_code}</td>
                        </tr>
                      )}
                      {movement.person_data.account_number && (
                        <tr>
                          <th scope="row">Cuenta</th>
                          <td>{movement.person_data.account_number}</td>
                        </tr>
                      )}
                      {movement.person_data.account_type && (
                        <tr>
                          <th scope="row">Tipo cuenta</th>
                          <td>{accountTypeMapper[movement.person_data.account_type]}</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <p>Desglose del pago</p>
              <Table className="align-items-left table-flush" size="sm" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Item</th>
                    <th>Abonos</th>
                    <th>Cargos</th>
                  </tr>
                </thead>
                <tbody>
                  {movement.original_amount_breakdown
                    && movement.original_amount_breakdown.map(bd => (
                      <tr>
                        <th scope="row">{bd.description}</th>
                        <td>{`$${bd.value}`}</td>
                        <td></td>
                      </tr>
                    ))}
                  {movement.additionals_breakdown
                    && movement.additionals_breakdown.map(bd => (
                      <tr>
                        <th scope="row">{bd.description}</th>
                        <td>{`$${bd.value}`}</td>
                        <td></td>
                      </tr>
                    ))}
                  {movement.discounts_breakdown
                    && movement.discounts_breakdown.map(bd => (
                      <tr>
                        <th scope="row">{bd.description}</th>
                        <td></td>
                        <td>({`$${bd.value}`})</td>
                      </tr>
                    ))}
                  <tr>
                    <th scope="row" style={{ color: 'orange' }}>TOTAL A PAGAR</th>
                    <td>{`$${movement.total_amount}`}</td>
                    <td></td>
                  </tr>
                  {movement.payment && (
                    <tr>
                      <th scope="row" style={{ color: 'orange' }}>TOTAL PAGADO</th>
                      <td>{`$${movement.payment}`}</td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <p
                style={{ color: 'royalblue', cursor: 'pointer' }}
                onClick={toggleGroupModal}
              >
                {groupsCollapseOpened && (<i className="ni ni-bold-up"></i>)}
                {!groupsCollapseOpened && (<i className="ni ni-bold-down"></i>)}
                &nbsp;Ver grupo de movimientos
              </p>
              <Collapse isOpen={groupsCollapseOpened} className="mb-3">
                {(fetchingGroup && group.length === 0) && (
                  <Row>
                    <div className="lds-facebook m-auto d-flex">
                      <div></div><div></div><div></div>
                    </div>
                  </Row>
                )}
                {(!fetchingGroup && group.length === 0) && (
                  <Row className="text-center">
                    <Col>
                      <em>No hay datos</em>
                    </Col>
                  </Row>
                )}
                {(group.length !== 0) && (
                  <Row>
                    <Col>
                      {group.filter(mov => mov.type === 'INCOME').map((mov, index) => (
                        <Card key={index} onClick={() => showMovement(mov.movement_id)}>
                          <CardBody>
                            <Row>
                              <Col>
                                <CardTitle className="text-uppercase text-muted mb-0">
                                  Recaudación
                                </CardTitle>
                                <span className="h3 font-weight-bold mb-0">
                                  {mov.payment ? `$${mov.payment}` : '-'}
                                </span>
                              </Col>
                              <Col className="col-auto">
                                <div className="icon icon-shape text-green rounded-circle shadow">
                                  <i className="fa fa-arrow-up" />
                                </div>
                              </Col>
                            </Row>
                            <Row className="mt-3 text-sm">
                              <Col>
                                <small><strong>Descripción:</strong> {mov.description}</small><br />
                                <small>
                                  <strong>Estado:</strong>
                                  &nbsp;
                                  <code
                                    style={{
                                      color: statusMapper[mov.status].color,
                                      fontSize: '100%',
                                      border: 'solid 1px',
                                      padding: '3px',
                                      borderRadius: '5px',
                                    }}
                                  >
                                    {statusMapper[mov.status].value}
                                  </code>
                                </small><br />
                                <small><strong>Monto estipulado:</strong> ${mov.total_amount}</small><br />
                                <small><strong>Fecha estipulada:</strong> {mov.agreed_date}</small><br />
                                <small><strong>Fecha pago:</strong> {mov.date}</small>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      ))}
                    </Col>
                    <Col>
                      {group.filter(mov => mov.type === 'OUTFLOW').map((mov, index) => (
                        <Card key={index} onClick={() => showMovement(mov.movement_id)}>
                          <CardBody>
                            <Row>
                              <Col>
                                <CardTitle className="text-uppercase text-muted mb-0">
                                  Pago clientes
                                </CardTitle>
                                <span className="h3 font-weight-bold mb-0">
                                  {mov.payment ? `$${mov.payment}` : '-'}
                                </span>
                              </Col>
                              <Col className="col-auto">
                                <div className="icon icon-shape text-blue rounded-circle shadow">
                                  <i className="fa fa-arrow-down" />
                                </div>
                              </Col>
                            </Row>
                            <Row className="mt-3 text-sm">
                              <Col>
                                <small><strong>Descripción:</strong> {mov.description}</small><br />
                                <small>
                                  <strong>Estado:</strong>
                                  &nbsp;
                                  <code
                                    style={{
                                      color: statusMapper[mov.status].color,
                                      fontSize: '100%',
                                      border: 'solid 1px',
                                      padding: '3px',
                                      borderRadius: '5px',
                                    }}
                                  >
                                    {statusMapper[mov.status].value}
                                  </code>
                                </small><br />
                                <small><strong>Monto estipulado:</strong> ${mov.total_amount}</small><br />
                                <small><strong>Fecha estipulada:</strong> {mov.agreed_date}</small><br />
                                <small><strong>Fecha pago:</strong> {mov.date}</small>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      ))}
                      {group.filter(mov => mov.type === 'PROFIT').map((mov, index) => (
                        <Card key={index} onClick={() => showMovement(mov.movement_id)}>
                          <CardBody>
                            <Row>
                              <Col>
                                <CardTitle className="text-uppercase text-muted mb-0">
                                  Ingreso Houm
                                </CardTitle>
                                <span className="h3 font-weight-bold mb-0">
                                  {mov.payment ? `$${mov.payment}` : '-'}
                                </span>
                              </Col>
                              <Col className="col-auto">
                                <div className="icon icon-shape text-orange rounded-circle shadow">
                                  <i className="fa fa-arrow-down" />
                                </div>
                              </Col>
                            </Row>
                            <Row className="mt-3 text-sm">
                              <Col>
                                <small><strong>Descripción:</strong> {mov.description}</small><br />
                                <small>
                                  <strong>Estado:</strong>
                                  &nbsp;
                                  <code
                                    style={{
                                      color: statusMapper[mov.status].color,
                                      fontSize: '100%',
                                      border: 'solid 1px',
                                      padding: '3px',
                                      borderRadius: '5px',
                                    }}
                                  >
                                    {statusMapper[mov.status].value}
                                  </code>
                                </small><br />
                                <small><strong>Monto estipulado:</strong> ${mov.total_amount}</small><br />
                                <small><strong>Fecha estipulada:</strong> {mov.agreed_date}</small><br />
                                <small><strong>Fecha pago:</strong> {mov.date}</small>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      ))}
                    </Col>
                  </Row>
                )}
              </Collapse>
            </Col>
          </Row>
          <Row>
            <Col>
              <p
                style={{ color: 'royalblue', cursor: 'pointer' }}
                onClick={() => setFilesCollapseOpened(!filesCollapseOpened)}
              >
                {filesCollapseOpened && (<i className="ni ni-bold-up"></i>)}
                {!filesCollapseOpened && (<i className="ni ni-bold-down"></i>)}
                &nbsp;Ver archivos adjuntos
              </p>
              <Collapse isOpen={filesCollapseOpened} className="mb-3">
                <Row className="text-center">
                  <Col>
                    <em>Funcionalidad no implementada!</em>
                  </Col>
                </Row>
              </Collapse>
            </Col>
          </Row>
          <Row>
            <Col>
              <p
                style={{ color: 'royalblue', cursor: 'pointer' }}
                onClick={() => setDocumentsCollapseOpened(!documentsCollapseOpened)}
              >
                {documentsCollapseOpened && (<i className="ni ni-bold-up"></i>)}
                {!documentsCollapseOpened && (<i className="ni ni-bold-down"></i>)}
                &nbsp;Ver documentos emitidos
              </p>
              <Collapse isOpen={documentsCollapseOpened} className="mb-3">
                <Row className="text-center">
                  <Col>
                    <em>Funcionalidad no implementada!</em>
                  </Col>
                </Row>
              </Collapse>
            </Col>
          </Row>
          <Row>
            <Col>
              <p
                style={{ color: 'royalblue', cursor: 'pointer' }}
                onClick={() => setMethodInfoCollapseOpened(!methodInfoCollapseOpened)}
              >
                {methodInfoCollapseOpened && (<i className="ni ni-bold-up"></i>)}
                {!methodInfoCollapseOpened && (<i className="ni ni-bold-down"></i>)}
                &nbsp;Ver información del método de pago
              </p>
              <Collapse isOpen={methodInfoCollapseOpened} className="mb-3">
                <Row className="text-center">
                  <Col>
                    <em>Funcionalidad no implementada!</em>
                  </Col>
                </Row>
              </Collapse>
            </Col>
          </Row>
        </Container>
      )}
    </SideModal>
  );
}

MovementInfoModal.defaultProps = {
  active: false,
  onClose: () => { },
  movement: {},
  loading: false,
};

MovementInfoModal.propTypes = {
  active: PropTypes.bool,
  onClose: PropTypes.func,
  movement: PropTypes.object,
  loading: PropTypes.bool,
};

export default MovementInfoModal;
