export const setObject = (key, value) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(key, btoa(JSON.stringify(value)));
  }
};

export const getObject = key => {
  if (typeof window !== 'undefined') {
    const value = localStorage.getItem(key);
    if (value) {
      return JSON.parse(atob(value));
    } else {
      return {};
    }
  } else {
    return {};
  }
};
