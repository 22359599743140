import React from "react";
import { PropTypes } from "prop-types";
// reactstrap components
import { Modal } from "reactstrap";

const SideModal = ({ active, onClose, renderHeader, renderFooter, children }) => {
  return (
    <>
      <Modal
        className="modal-dialog-centered modal-secondary"
        isOpen={active}
        toggle={onClose}
        scrollable={true}
        style={{ maxWidth: '75%' }}
      >
        <div className="modal-header">
          {renderHeader()}
        </div>
        <div className="modal-body">
          {children}
        </div>
        <div className="modal-footer">
          {renderFooter()}
        </div>
      </Modal>
    </>
  );
}

SideModal.defaultProps = {
  active: false,
  onClose: () => {},
  renderHeader: () => {},
  renderFooter: () => {},
};

SideModal.propTypes = {
  active: PropTypes.bool,
  onClose: PropTypes.func,
  renderHeader: PropTypes.func,
  renderFooter: PropTypes.func,
};


export default SideModal;
