import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import jwt_decode from "jwt-decode";
import { env } from "../env.js";

const AUDIENCE = env.REACT_APP_AUDIENCE;
const OLD_TOKEN = 'https://api.houm.com/old_token';


export const useAuthToken = () => {
    const [authToken, setAuthToken] = useState('');
    const { getAccessTokenSilently } = useAuth0();
    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently({
                    audience: AUDIENCE,
                });
                setAuthToken(token);
            } catch (e) {
                console.error(e);
            }
        })();
    }, [getAccessTokenSilently]);

    return authToken;
}

export const getOldToken = (authToken) => {
    let jwt_decoded = jwt_decode(authToken);
    return jwt_decoded[OLD_TOKEN];
}
