import React from "react";
import { Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { setObject } from "./helpers/storage";
import { verifyRole } from "./helpers/verifyRole";

const PrivateComponent = ({ children }) => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();

  if (isLoading) {
    return <h1>LOADING...</h1>;
  }

  if (isAuthenticated) {
    const roles = user["https://api.houm.com/roles"];
    if (verifyRole(roles, "all")) {
      setObject("userData", {
        name: user.name,
        email: user.email,
      });
      return children;
    } else {
      logout({ returnTo: `${window.location.origin}/auth` });
    }
  }
  return <Redirect to='/auth' />;
};

export default PrivateComponent;
