import React from 'react';
import { PropTypes } from 'prop-types';

import {
  Container,
  Button,
  Row,
  Table,
  Col,
} from "reactstrap";

import SideModal from './SideModal.jsx';


const BankAccountInfoModal = ({ active, onClose, bankAccountRecord, loading }) => {
  const accountTypeMapper = {
    'CURRENT': 'Corriente',
    'SAVING': 'Ahorro',
    'SIGHT': 'Vista',
  };

  return (
    <SideModal
      active={active}
      onClose={onClose}
      renderHeader={() => (
        <h5 className="modal-title">
          Detalle de la cuenta bancaria
        </h5>
      )}
      renderFooter={() => (
        <Button
          color="warning"
          data-dismiss="modal"
          type="button"
          onClick={onClose}
        >
          Cerrar
        </Button>
      )}
    >
      {loading && (
        <Container>
          <div className="lds-facebook m-auto d-flex">
            <div></div><div></div><div></div>
          </div>
        </Container>
      )}
      {(!loading && bankAccountRecord.length === 0) && (
        <Container>
          <Row>
            <h2>Ha ocurrido un error</h2>
          </Row>
        </Container>
      )}
      {(!loading && bankAccountRecord.length !== 0) && (
        <Container>
          <Row>
            <Col>
              <p>Información de la cuenta registrada</p>
              <Table className="align-items-left table-flush" size="sm" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Item</th>
                    <th>Valor</th>
                    <th>Item</th>
                    <th>Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Titular</th>
                    <td>{bankAccountRecord[0].holder}</td>
                    {bankAccountRecord[0].registered && (
                      <>
                        <th scope="row">Registrada</th>
                        <td>{bankAccountRecord[0].registered ? 'Sí' : 'No'}</td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <th scope="row">Identificador</th>
                    <td>{bankAccountRecord[0].identifier}</td>
                    <th scope="row">Banco</th>
                    <td>{bankAccountRecord[0].bank_name}</td>
                  </tr>
                  <tr>
                    <th scope="row">Documento</th>
                    <td>{bankAccountRecord[0].document}</td>
                    <th scope="row">Código banco</th>
                    <td>{bankAccountRecord[0].bank_code}</td>
                  </tr>
                  <tr>
                    <th scope="row">Tipo de documento</th>
                    <td>{bankAccountRecord[0].document_type || ''}</td>
                    <th scope="row">Tipo de cuenta</th>
                    <td>{accountTypeMapper[bankAccountRecord[0].account_type]}</td>
                  </tr>
                  <tr>
                    <th scope="row">Email</th>
                    <td>{bankAccountRecord[0].email}</td>
                    <th scope="row">Número cuenta</th>
                    <td>{bankAccountRecord[0].account_number}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          {bankAccountRecord.length > 1 && (
            <Row>
              <Col>
                <p>Registro de modificaciones</p>
                {bankAccountRecord.slice(1).map((bk) => (
                  <>
                    <small><em>Modificación: {bk.deactivated_at}</em></small>
                    <Table className="align-items-left table-flush" size="sm" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th>Item</th>
                          <th>Valor</th>
                          <th>Item</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">Titular</th>
                          <td>{bk.holder}</td>
                          <th scope="row">Banco</th>
                          <td>{bk.bank_name}</td>
                        </tr>
                        <tr>
                          <th scope="row">Documento</th>
                          <td>{bk.document}</td>
                          <th scope="row">Código banco</th>
                          <td>{bk.bank_code}</td>
                        </tr>
                        <tr>
                          <th scope="row">Tipo de documento</th>
                          <td>{bk.document_type || ''}</td>
                          <th scope="row">Tipo de cuenta</th>
                          <td>{accountTypeMapper[bk.account_type]}</td>
                        </tr>
                        <tr>
                          <th scope="row">Email</th>
                          <td>{bk.email}</td>
                          <th scope="row">Número cuenta</th>
                          <td>{bk.account_number}</td>
                        </tr>
                        {bk.registered && (
                          <tr>
                            <th scope="row">Registrada</th>
                            <td>{bk.registered ? 'Sí' : 'No'}</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </>
                ))}
              </Col>
            </Row>
          )}
        </Container>
      )}
    </SideModal>
  )
};

BankAccountInfoModal.defaultProps = {
  active: false,
  onClose: () => {},
  bankAccountRecord: [],
  loading: false,
};

BankAccountInfoModal.propTypes = {
  active: PropTypes.bool,
  onClose: PropTypes.func,
  bankAccountRecord: PropTypes.array,
  loading: PropTypes.bool,
};

export default BankAccountInfoModal;
