import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { getObject } from "../../helpers/storage";

const Home = () => {
  const user = getObject("userData");
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <Card>
            <CardBody className="py-8">
              <div className="text-center">
                <h1 className="h1 title">
                  <span>Hola {user.name}!</span>
                </h1>
                <h3 className="h3 font-weight-light">
                  <span>Puedes comenzar seleccionando alguna de las opciones al lado izquierdo</span>
                </h3>
                <div
                  className="h1 title align-items-center d-flex justify-content-center"
                  style={{ color: 'orange' }}
                >
                  <i className="ni ni-bold-left" style={{ fontSize: '50px' }}></i>
                  <i className="ni ni-bold-left" style={{ fontSize: '35px' }}></i>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
