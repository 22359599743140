import React from 'react';
import PageHeader from '../../../../components/Headers/PageHeader.jsx';
import CheckDuplicates from './cards/CheckDuplicates'
import UploadPayroll from './cards/UploadPayroll.jsx';

const Payroll = () => {

  return (
    <div>
      <PageHeader
        title={'Nóminas de pago.'}
        breadcrumb={[{ href: '#', title: 'Herramientas' }]}
      />

      <UploadPayroll/>
      <CheckDuplicates/>
    </div>
  );
}

export default Payroll;
