import React from 'react';
import { PropTypes } from 'prop-types';

import {
  Card,
  CardHeader,
  CardBody,
  Container,
} from 'reactstrap';


const CustomInfo = ({ renderHeader, renderInfo, loading, showData }) => {
  return (
    <Container fluid>
      <Card className="border-0 mb-4">
        <CardHeader className="border-0 m-auto">{renderHeader()}</CardHeader>
        <CardBody className="px-lg-7">
          {loading && (
            <div className="lds-facebook m-auto d-flex">
              <div></div><div></div><div></div>
            </div>
          )}
          {(!showData && !loading) && (
            <div className="text-center my-3">
              <em><small>No hay datos</small></em>
            </div>
          )}
          {(showData && !loading) && renderInfo()}
        </CardBody>
      </Card>
    </Container>
  );
}

CustomInfo.defaultProps = {
  renderHeader: () => {},
  renderInfo: () => {},
  loading: false,
  showData: false,
};

CustomInfo.propTypes = {
  renderHeader: PropTypes.func,
  renderInfo: PropTypes.func,
  loading: PropTypes.bool,
  showData: PropTypes.bool,
};

export default CustomInfo;
