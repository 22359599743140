import React, { useState, useRef, useContext } from 'react';
import { FormGroup, Form, Input, Button, Row } from "reactstrap";
import CustomTable from '../components/CustomTable.jsx';
import PageHeader from '../../../components/Headers/PageHeader.jsx';
import BankAccountInfoModal from '../components/modals/BankAccountInfoModal.jsx';
import { AdminStateContext } from '../../../providers/AdminStateProvider.jsx';
import { getBankAccounts, getBankAccountRecord } from '../../../api';
import { useAuthToken } from '../../../hooks/AuthHook.jsx';

const Accounts = () => {
  const [accounts, setAccounts] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [recordFetching, setRecordFetching] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [bankAccountRecord, setBankAccountRecord] = useState([]);
  const [adminState,] = useContext(AdminStateContext);
  const inputEl = useRef();
  const authToken = useAuthToken();

  const columns = [
    'Identificador',
    'Titular',
    'Documento',
    'Banco',
    'Cuenta',
  ];
  const sizes = [2, 3, 2, 2, 3];

  const fetchBankAccounts = async () => {
    const personId = inputEl.current.value;
    if (personId) {
      setIsFetching(true);
      const response = await getBankAccounts(adminState.country.toLowerCase(), authToken, personId);
      if (response && response.bank_accounts) {
        setAccounts(parseResponse(response));
      }
      setIsFetching(false);
    }
  };

  const parseBankAccountType = (type) => {
    switch (type) {
      case 'CURRENT':
        return 'Cuenta Corriente';
      case 'SIGHT':
        return 'Cuenta Vista';
      case 'SAVING':
        return 'Cuenta de Ahorro';
      default:
        return 'Otro';
    }
  }

  const parseResponse = (response) => {
    const { bank_accounts } = response;
    return bank_accounts.map(account => (
      {
        id: account.identifier,
        data: [
          account.identifier,
          account.holder,
          account.document,
          account.bank_name,
          `${parseBankAccountType(account.account_type)}: ${account.account_number}`,
        ],
      }
    ));
  };

  const selectBankAccount = async (id) => {
    if (id) {
      const personId = inputEl.current.value;
      setRecordFetching(true);
      setModalActive(true);
      const response = await getBankAccountRecord(
        adminState.country.toLowerCase(),
        authToken,
        personId,
        id,
      );
      if (response && response.bank_accounts) {
        setBankAccountRecord(response.bank_accounts);
      }
      setRecordFetching(false);
    }
  }

  return (
    <div>
      <PageHeader
        title={'Cuentas bancarias del propietario'}
        breadcrumb={[{ href: '#', title: 'Propietarios' }]}
      />
      <BankAccountInfoModal
        active={modalActive}
        onClose={() => setModalActive(false)}
        bankAccountRecord={bankAccountRecord}
        loading={recordFetching}
      />
      <CustomTable
        columns={columns}
        sizes={sizes}
        data={accounts}
        loading={isFetching}
        onClick={selectBankAccount}
        renderHeader={() => (
          <Form onSubmit={e => e.preventDefault()}>
            <FormGroup className="m-auto">
              <label><small>Ingresa el ID del propietario</small></label>
              <Row>
                <Input
                  className="col form-control-muted form-control-sm mx-2"
                  placeholder="ID del propietario"
                  type="text"
                  innerRef={inputEl}
                />
                <Button
                  color="primary"
                  size="sm"
                  type="button"
                  onClick={fetchBankAccounts}
                >
                  Obtener cuentas bancarias
                </Button>
              </Row>
            </FormGroup>
          </Form>
        )}
      />
    </div>
  );
}

export default Accounts;
