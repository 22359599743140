import React, { useContext, useCallback, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import {
  Container,
  Col,
  FormGroup,
  Form,
  Input,
  Button,
  Row,
} from "reactstrap";

import CustomTable from '../components/CustomTable.jsx';
import PageHeader from '../../../components/Headers/PageHeader.jsx';
import MovementInfoModal from '../components/modals/MovementInfoModal.jsx';
import { AdminStateContext } from '../../../providers/AdminStateProvider.jsx';
import { getMovements, getMovement, getMovementsCL } from '../../../api';
import { useAuthToken } from '../../../hooks/AuthHook.jsx';
import {
  columns,
  sizes,
  typeMapper,
  conceptMapper,
  statusByCountry,
  conceptsByCountry
} from "./constants";
import { swalErrorMessage } from "../utils"

const Movements = () => {
  let location = useLocation();
  let history = useHistory();
  const searchParams = qs.parse(location.search);
  const [movements, setMovements] = useState([]);
  const [stats, setStats] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [firstFetch, setFirstFetch] = useState(false);
  const [typeSelector, setTypeSelector] = useState('default');
  const [conceptSelector, setConceptSelector] = useState('default');
  const [statusSelector, setStatusSelector] = useState('default');
  const [dateFromSelector, setDateFromSelector] = useState();
  const [dateToSelector, setDateToSelector] = useState();
  const [modalActive, setModalActive] = useState();
  const [movementInfo, setMovementInfo] = useState();
  const [movementFetching, setMovementFetching] = useState();
  const [originalMovementsCL, setOriginalMovementsCL] = useState([]);
  const [adminState] = useContext(AdminStateContext);
  const authToken = useAuthToken();
  const { country, legacyToken } = adminState;

  const renderComment = useCallback(() => (
    <p className="mx-9">
      Consultando movimientos de tipo <code>{typeMapper[typeSelector]}</code><br />
      por concepto <code>{conceptMapper[conceptSelector]}</code> en estado <code>
      {statusByCountry[country.toLowerCase()][statusSelector]}</code><br />
      desde <code>{dateFromSelector}</code> hasta <code>{dateToSelector}</code>
    </p>
  ), [conceptSelector, dateFromSelector, dateToSelector, statusSelector, typeSelector, country]);

  const fetchMovements = async () => {
    if (dateFromSelector && dateToSelector) {
      if (!firstFetch) {
        setFirstFetch(true);
      }
      setIsFetching(true);
      let response;
      if (country === 'CL' && conceptSelector !== "reservation") {
        response = await getMovementsCL(
          legacyToken,
          conceptSelector,
          {
            _from: dateFromSelector,
            _to: dateToSelector,
          },
        );
      } else {
        response = await getMovements(
          country.toLowerCase(),
          authToken,
          typeSelector,
          conceptSelector,
          statusSelector,
          {
            _from: dateFromSelector,
            _to: dateToSelector,
          },
        );
      }
      if (response && response.movements) {
        const { movements } = response;
        if (country === 'CL') {
          setOriginalMovementsCL(movements);
          setStats({
            income: response.summary?.total_real_income || 0,
            paid: response.summary?.total_outflow || 0,
            profit: response.summary?.total_profit || 0,
            totalMovements: movements.length,
            toPay: response.summary?.total_outflow || 0,
          });
        } else {
          setStats({
            income: null,
            paid: movements.map(mov => mov.payment || 0).reduce((acc, curr) => acc + curr, 0),
            profit: null,
            totalMovements: movements.length,
            toPay: movements.reduce((acc, curr) => acc + curr.total_amount, 0),
          });
        }
        setMovements(parseResponse(response));
      }
      setIsFetching(false);
    }
  };
  
  const parseResponse = (response) => {
    const { movements } = response;
    return movements.map(movement => (
      {
        id: movement.movement_id,
        data: [
          `${movement.subscription_id || '-'} / ${movement.property_id || '-'}`,
          movement.description,
          movement.payment ? `$${movement.payment}` : '-',
          movement.date || '-',
          movement.method,
        ],
      }
    ));
  };

  const closeModal = () => {
    setModalActive(false);
    history.push({ search: '' });
  };

  useEffect(() => {
    if (searchParams.movement_id) {
      setModalActive(true);
      (async (id) => {
        setMovementFetching(true);
        try {
          const response = await getMovement(country.toLowerCase(), authToken, id);
          if (response.movement_id) {
            setMovementInfo(response);
          }
        } catch (e) {
          swalErrorMessage('Existio un error en consultar su requerimiento, vuelva a intentarlo');
        } finally { setMovementFetching(false); }
      })(searchParams.movement_id)
    }
  }, [authToken, country, location, originalMovementsCL, searchParams.movement_id]);

  useEffect(() => {
    setConceptSelector('default');
  }, [typeSelector]);

  useEffect(() => {
    if (country === 'CL') {
      setStatusSelector('paid');
      setTypeSelector('income');
    }
    setConceptSelector('default');
    setMovements([]);
  }, [country]);

  return (
    <div>
      <PageHeader
        title={'Ver movimientos'}
        breadcrumb={[{ href: '#', title: 'Movimientos' }]}
      />
      <MovementInfoModal
        active={modalActive}
        onClose={closeModal}
        movement={movementInfo}
        loading={movementFetching}
      />
      <CustomTable
        columns={columns}
        sizes={sizes}
        data={movements}
        loading={isFetching}
        onClick={(id) => history.push({ search: `?movement_id=${id}` })}
        renderHeader={() => (
          <Container>
            <Row>
              <Form className="row align-items-end justify-content-center">
                <FormGroup className="my-0 mx-3">
                  <label><small>Ingresa tipo de movimiento</small></label>
                  <Row>
                    <Input
                      className="col form-control-muted form-control-sm mx-2"
                      type="select"
                      value={typeSelector}
                      onChange={(e) => setTypeSelector(e.target.value)}
                    >
                      <option value="default" disabled hidden>-- Tipo de movimiento --</option>
                      <option value="income">Recaudación</option>
                      <option value="outflow">Salida</option>
                      <option value="profit">Ingreso Houm</option>
                    </Input>
                  </Row>
                </FormGroup>
                <FormGroup className="my-0 mx-3">
                  <label><small>Ingresa concepto</small></label>
                  <Row>
                    <Input
                      className="col form-control-muted form-control-sm mx-2"
                      type="select"
                      value={conceptSelector}
                      onChange={(e) => setConceptSelector(e.target.value)}
                    >
                      <option value="default" disabled hidden>-- Concepto --</option>
                      {country && conceptsByCountry[country.toLowerCase()].map(
                        ({ value, label }, index) => (
                          <option key={index} value={value}>{label}</option>
                        )
                      )}
                    </Input>
                  </Row>
                </FormGroup>
                <FormGroup className="my-0 mx-3">
                <label><small>Ingresa estado</small></label>
                  <Row>
                    <Input
                      className="col form-control-muted form-control-sm mx-2"
                      type="select"
                      value={statusSelector}
                      onChange={(e) => setStatusSelector(e.target.value)}
                    >
                      {country && Object.entries(statusByCountry[country.toLowerCase()]).map(
                        ([value, label]) => (
                          <option key={value} value={value}>{label}</option>
                        )
                      )}
                  </Input>
                  </Row>
                </FormGroup>
                <FormGroup className="my-0 mx-3">
                  <label><small>Desde</small></label>
                  <Row>
                    <Input
                      className="col form-control-muted form-control-sm mx-2"
                      type="date"
                      onChange={(e) => setDateFromSelector(e.target.value)}
                    />
                  </Row>
                </FormGroup>
                <FormGroup className="my-0 mx-3">
                  <label><small>Hasta</small></label>
                  <Row>
                    <Input
                      className="col form-control-muted form-control-sm mx-2"
                      type="date"
                      onChange={(e) => setDateToSelector(e.target.value)}
                    />
                  </Row>
                </FormGroup>
                <Button
                  color="primary"
                  size="sm"
                  type="button"
                  className="my-3 mx-3"
                  onClick={fetchMovements}
                  disabled={
                    typeSelector === 'default'
                    || conceptSelector === 'default'
                    || statusSelector === 'default'
                    || !dateFromSelector
                    || !dateToSelector
                  }
                >
                  Obtener movimientos
                </Button>
              </Form>
            </Row>
            {firstFetch && (
              <Row className="align-items-center text-center justify-content-center mt-3">
                <Col>
                  {renderComment()}
                </Col>
                <Col className="col-auto">
                  {isFetching && (
                    <div className="lds-facebook m-auto d-flex">
                      <div></div><div></div><div></div>
                    </div>
                  )}
                  {!isFetching && (
                    <div className="text-sm">
                      <Row>
                        <strong>Total movimientos:</strong>
                        &nbsp;{stats.totalMovements}
                      </Row>
                      {stats.income && (
                        <Row>
                          <strong>Recaudado:</strong>
                          &nbsp;{stats.income}
                        </Row>
                      )}
                      <Row>
                        <strong>Monto total a pagar:</strong>
                        &nbsp;{stats.toPay}
                      </Row>
                      <Row>
                        <strong>Monto total pagado:</strong>
                        &nbsp;{stats.paid}
                      </Row>
                      {stats.profit && (
                        <Row>
                          <strong>Ingreso Houm:</strong>
                          &nbsp;{stats.profit}
                        </Row>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </Container>
        )}
      />
    </div>
  );
};

export default Movements;
