export const parseDate = (date) => {
  const day = date.getDay();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day > 9 ? day : '0' + day}-${month > 9 ? month : '0' + month}-${year}`;
}

export const parseDateCommssions = (date) => {
  date = date.split("-");
  return [date[2], date[1], date[0]].join('-');
}

export const parseMoney = (number) => {
  if (number < 0) {
    return `($${number * -1})`;
  }
  return `$${number}`;
}
