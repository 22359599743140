/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from 'react';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';

const Footer = () => {
  return (
    <>
      <Container fluid>
        <footer className='footer pt-0'>
          <Row className='align-items-center justify-content-center'>
            <Col>
              <div className='copyright text-center text-muted'>
                © {new Date().getFullYear()}{' '}
                <a
                  className='font-weight-bold ml-1'
                  href='https://houm.com'
                  target='_blank'
                >
                  Houm
                </a>
              </div>
            </Col>
          </Row>
        </footer>
      </Container>
    </>
  );
};

export default Footer;
