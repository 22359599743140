import React, { useState, useRef } from 'react';
import { FormGroup, Form, Button, Row, CustomInput, Input } from "reactstrap";
import moment from "moment";
import CustomInfo from '../../../components/CustomInfo.jsx';
import { uploadPayrollPayments } from '../../../../../api';
import { useAuthToken } from '../../../../../hooks/AuthHook.jsx';


const UploadPayroll = () => {
  const authToken = useAuthToken();

  const [conceptUpload, setConceptUpload] = useState('default')
  const [dateUpload, setDateUpload] = useState(moment())
  const uploadFileRef = useRef()
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [errorInputUploadData, setErrorInputUploadData] = useState(false)
  const [successUpload, setSuccessUpload] = useState(false)

  const uploadPayroll = async () => {
    setLoadingUpload(true)
    setErrorInputUploadData(false)
    setSuccessUpload(false)

    const body = new FormData()
    body.append("payroll", uploadFileRef.current.files[0])
    const response = await uploadPayrollPayments(
      authToken,
      dateUpload,
      conceptUpload,
      body)

    if (response.response) {
      setSuccessUpload(true)
    } else {
      setErrorInputUploadData(true)
    }

    setLoadingUpload(false)
  }

  return (
      <CustomInfo
        showData={true}
        loading={loadingUpload}
        renderHeader={
          e => (
            <Form onSubmit={e => e.preventDefault()}>
              <FormGroup className="m-auto">
                Subir información de nóminas de pago
                <Row>
                  <FormGroup className='my-0 mx-3'>
                    <label><small>Nómina</small></label>
                    <CustomInput
                      type="file"
                      name="uploadFileData"
                      id="uploadFileData"
                      className="d-flex"
                      innerRef={uploadFileRef}
                    />
                  </FormGroup>

                  <FormGroup className="my-0 mx-3">
                    <label><small>Concepto</small></label>
                    <Input
                      className="col form-control-muted form-control-sm mx-2"
                      type="select"
                      value={conceptUpload}
                      onChange={(e) => setConceptUpload(e.target.value)}
                    >
                      <option value="default" disabled hidden>-- Concepto --</option>
                      <option value="BASIC">Básico</option>
                      <option value="PREMIUM">Premium</option>
                      <option value="DIRECT">Transferencia directa</option>
                      <option value="REFUND">Reembolso</option>
                      <option value="BROKERAGE">Solo corretaje</option>
                    </Input>
                  </FormGroup>

                  <FormGroup className="my-0 mx-3">
                    <label><small>Fecha nómina de pago</small></label>
                    <Input
                      className="col form-control-muted form-control-sm mx-2"
                      type="date"
                      onChange={(e) => setDateUpload(e.target.value)} />
                  </FormGroup>

                  <Button
                    color="primary"
                    className="my-3 mx-3"
                    size="sm"
                    type="button"
                    onClick={uploadPayroll}
                  >
                    Subir información
                  </Button>

                </Row>

                {errorInputUploadData && (
                  <Row style={{ justifyContent: 'center', color: 'red' }}>
                    Error en la nómina o en los parámetros ingresados.
                  </Row>
                )}

                {successUpload && (
                  <Row style={{ justifyContent: 'center', color: 'green' }}>
                    Datos subidos con éxito.
                  </Row>
                )}

              </FormGroup>
            </Form>
          )
        }
      />
  );
}

export default UploadPayroll;
