import React, { useRef, useState, useContext, useEffect } from "react";
import { isEmpty } from "lodash";
import PageHeader from "../../../components/Headers/PageHeader.jsx";
import BreakDown from "./components/CommissionBreakDown.jsx";
import CommissionForm from "./components/CommissionForm.jsx";
import { Container, Card, CardBody, Row, Col } from "reactstrap";

import { download } from "../../../helpers/downloadFile";
import { parseDateCommssions } from "../../../helpers/parse";
import { useAuthToken } from "../../../hooks/AuthHook.jsx";
import { AdminStateContext } from "../../../providers/AdminStateProvider.jsx";
import {
  getExecutives,
  getExecutiveCommission,
  getExecutiveReport,
} from "../../../api"; 


const Executives = () => {
  const authToken = useAuthToken();
  const [executives, setexecutives] = useState([]);
  const [executive, setexecutive] = useState({});
  const [commission, setCommission] = useState({});
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const executiveIn = useRef();
  const [adminState] = useContext(AdminStateContext);

  useEffect(() => {
    (async function () {
      const results = await getExecutives();
      setexecutives(results);
    })();
  }, []);

  const getCommission = async () => {
    const chosen = executives.find(
      app => app.id === parseInt(executiveIn.current.value)
    );
    setexecutive(chosen);
    const result = await getExecutiveCommission(
      adminState.country.toLowerCase(),
      authToken,
      chosen.id,
      parseDateCommssions(fromDate),
      parseDateCommssions(toDate)
    );
    setCommission(result);
  };

  const getReport = async () => {
    const response = await getExecutiveReport(
      adminState.country.toLowerCase(),
      authToken,
      executive.id,
      parseDateCommssions(toDate),
      executive.name
    );
    download(response, `Reporte ${executive.name}.xlsx`);
  };

  return (
    <div>
      <PageHeader
        title={"Comisiones Ejecutivos y Lead qualifiers"}
        breadcrumb={[{ href: "#", title: "Comisiones" }]}
      />

      <Container fluid>
        <Card className='border-0 mb-4'>
          <CardBody className='px-lg-7'>
            <Row>
              <CommissionForm
                title={"Ejecutivo"}
                name={executive.name}
                inputRef={executiveIn}
                persons={executives}
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
                getCommission={getCommission}
                getReport={getReport}
                commission={commission}
              />
            </Row>
            {!isEmpty(commission) && (
              <Row style={{ marginLeft: 40, marginTop: 30 }}>
                <Col sm={{ size: "auto" }}>
                  <BreakDown commission={commission} />
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Executives;
