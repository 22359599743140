import React, { useState, useRef } from 'react';
import { FormGroup, Form, Button, Row, CustomInput, Input } from "reactstrap";
import moment from "moment";
import { getDuplicatedPayrollPayments } from '../../../../../api';
import { useAuthToken } from '../../../../../hooks/AuthHook.jsx';
import CustomInfo from '../../../components/CustomInfo.jsx';


const CheckDuplicates = () => {
  const authToken = useAuthToken();

  const [dateFrom, setDateFrom] = useState(moment())
  const [dateTo, setDateTo] = useState(moment())
  const [conceptCheck, setConceptCheck] = useState('default')
  const checkFileRef = useRef()
  const [errorInputCheckData, setErrorInputCheckData] = useState(false)
 
  const downloadFile = async (filename, fileExtension, getDuplicates) => {
    setErrorInputCheckData(false)

    // check fot validation of the data for the endpoint.
    if (conceptCheck === 'default'){
      setErrorInputCheckData(true)
      return
    }

    if (typeof dateFrom !== "string"){
      setErrorInputCheckData(true)
      return
    }

    if (typeof dateTo !== "string"){
      setErrorInputCheckData(true)
      return
    }

    const body = new FormData()
    body.append("payroll", checkFileRef.current.files[0])
    const response = await getDuplicatedPayrollPayments(authToken, dateFrom, dateTo, conceptCheck, getDuplicates, body)

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(new Blob([response]));
    const name = `${filename}.${moment().format("DD-MM-YY")}.${fileExtension}`;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

  };

  return (
      <CustomInfo
        showData={true}
        renderHeader={() => (
          <Form onSubmit={e => e.preventDefault()}>
            <FormGroup className="m-auto">
              Chequeo de pagos duplicados
              <Row>
                <FormGroup className="my-0 mx-3">
                  <label><small>Nómina</small></label>
                  <CustomInput
                    type="file"
                    name="uploadFileCheck"
                    id="uploadFileCheck"
                    className="d-flex"
                    innerRef={checkFileRef}
                  />
                </FormGroup>

                <FormGroup className="my-0 mx-3">
                  <label><small>Concepto</small></label>
                  <Input
                    className="col form-control-muted form-control-sm mx-2"
                    type="select"
                    value={conceptCheck}
                    onChange={(e) => setConceptCheck(e.target.value)}
                  >
                    <option value="default" disabled hidden>-- Concepto --</option>
                    <option value="BASIC">Básico</option>
                    <option value="PREMIUM">Premium</option>
                    <option value="DIRECT">Transferencia directa</option>
                    <option value="REFUND">Reembolso</option>
                    <option value="BROKERAGE">Solo corretaje</option>
                  </Input>
                </FormGroup>

                <FormGroup className="my-0 mx-3">
                  <label><small>Fecha incial</small></label>
                  <Input
                    className="col form-control-muted form-control-sm mx-2"
                    type="date"
                    onChange={(e) => setDateFrom(e.target.value)}
                  />
                </FormGroup>

                <FormGroup className="my-0 mx-3">
                  <label><small>Fecha final</small></label>
                  <Input
                    className="col form-control-muted form-control-sm mx-2"
                    type="date"
                    onChange={(e) => setDateTo(e.target.value)}
                  />
                </FormGroup>

                <Button
                  color="primary"
                  className="my-3 mx-3"
                  size="sm"
                  type="button"
                  onClick={() => {
                    setErrorInputCheckData(false)
                    downloadFile("pagos_duplicados", "xlsx", true);
                  }}
                >
                  Obtener pagos duplicados
                </Button>

                <Button
                  color="primary"
                  className="my-3 mx-3"
                  size="sm"
                  type="button"
                  onClick={() => {
                    setErrorInputCheckData(false)
                    downloadFile("pagos_duplicados", "xlsx", false);
                  }}
                >
                  Obtener pagos no duplicados
                </Button>

              </Row>
              {errorInputCheckData && (
                <Row style={{ justifyContent: 'center', color: 'red' }}>
                  Error en la nómina o en los parámetros ingresados.
                </Row>
              )}
            </FormGroup>
          </Form>
        )}
      />
  );
}

export default CheckDuplicates;
